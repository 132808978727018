import React from "react"
import digitalSignageStyles from '../../style/digitalsignage.module.scss'
import Image from "../global/image.js"


const ServicesDigitalSignageCard = ( props ) => {


    return (
        <div className={digitalSignageStyles.card}>

            <Image src={props.icon.src} alt={props.icon.alt} />
            <h4>{props.title}</h4>
            <p>{props.text}</p>

        </div>

    )
}

export default ServicesDigitalSignageCard

