import React, { useRef, useEffect } from "react"
import globalStyles from '../../style/global.module.scss'


const HorizontalScrollSection = (props) => {

    const scrollSection = useRef(0)
    const viewport = useRef(0)
    const slider = useRef(0)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        window.addEventListener("resize", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
            window.removeEventListener("resize", handleScroll)
        }
    })

    const handleScroll =  () => {
        let viewportWidth = viewport.current.clientWidth
        let sliderWidth = slider.current.scrollWidth
        let sliderOverflow = sliderWidth - viewportWidth

        // set height of the scroll section
        scrollSection.current.style.height = `calc(100vh + ${sliderOverflow}px)`

       // calculate the scroll position within the section
        let scrollTop = window.pageYOffset;
        let sectionHeight = scrollSection.current.clientHeight
        let startOfSection = scrollSection.current.offsetTop

        let innerScrollPosition = {
            absolute: scrollTop - startOfSection,
            percentageTop: (scrollTop - startOfSection) / sectionHeight * 100,
            percentageBottom: ((scrollTop + window.innerHeight) - startOfSection) / sectionHeight * 100
        }

        // above section
        if (innerScrollPosition.absolute < 0) {
            slider.current.style.transform = `translateX(0%)`;
            viewport.current.style.position = 'relative'
            viewport.current.style.left = `0px`

        }

        //within section
        else if (innerScrollPosition.percentageBottom < 100) {
            viewport.current.style.left = `${viewport.current.offsetLeft}px`
            viewport.current.style.position = 'fixed'
            viewport.current.style.top = 0

            // moves slider to the left depending on scroll percentage from top of section 
            if (innerScrollPosition.absolute < sliderOverflow) slider.current.style.transform = `translateX(-${innerScrollPosition.absolute}px)`
        }

        // below section
        else {
            // positions viewport at the end of the scroll section
                viewport.current.style.position = 'relative'
                viewport.current.style.top = `calc(${(scrollSection.current.clientHeight)}px - 100vh)`
                viewport.current.style.left = 0

        }
    


    }


    return (
        <div ref={scrollSection} className={globalStyles.horizontalScrollSection}>
            <div ref={viewport} className={globalStyles.viewport}>

                {props.title}
                <div ref={slider}  className={globalStyles.slider}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default HorizontalScrollSection
