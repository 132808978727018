import React from "react"
import Layout from '../components/global/layout.js'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import LandingSection from "../components/global/landingsection.js"
import ContentSection from "../components/global/contentsection.js"
import { Helmet } from "react-helmet"
import digitalSignageStyles from '../style/digitalsignage.module.scss'
import globalStyles from '../style/global.module.scss'
import ServicesDigitalSignageCard from "../components/services/services.digitalsignage.card.js"
import HorizontalScrollSection from "../components/global/horizontal.scroll.section.js"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Image from "../components/global/image.js"
import LazySlide from "../components/global/lazy.slide.js"

import targetIcon from "../img/services/target.svg"
import touchIcon from "../img/services/touch.svg"
import bullhornIcon from "../img/services/bullhorn.svg"
import chartIcon from "../img/services/chart.svg"
import moneyIcon from "../img/services/money.svg"

import DesktopMockup1 from "../img/services/digitalsignage_desktop_1.png"
import MobileMockup from "../img/services/digitalsignage_mobile.png"


//TODO ANIMATIONEN

const DigitalSignage = () => {

    const intl = useIntl()

    const subTitleObject = <p>{intl.formatMessage({ id: "Services.digitalsignage.subtitle" })}</p>
    const subTitle = subTitleObject.props.children

    return (

        <Layout>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <title> Spherical Elephant | Digital Signage </title>
                <meta name="description" content="Full-stack Software Design und Development - Ein Einblick in unsere Lieblingsprojekte"></meta>
            </Helmet>

            <LandingSection
                h1={<FormattedMessage id="Services.digitalsignage.h1" />}
                title={<FormattedMessage id="Services.digitalsignage.title" />}
                subtitle={subTitle}
            />

            <ContentSection>
                <div className={digitalSignageStyles.digitalSignageContent}>

                    {/* Wieso Digital Signage? */}
                    <section>
                        <div className={digitalSignageStyles.whyDigitalSignage}>
                            <div>
                                <h3> <FormattedMessage id="Services.digitalsignage.why.h" /> </h3>
                                <p className={digitalSignageStyles.description}> <FormattedMessage id="Services.digitalsignage.why.p" /> </p>
                            </div>
                            <div className={digitalSignageStyles.argSection}>
                                <LazySlide duration="1s" right={true}>
                                    <ServicesDigitalSignageCard title={<FormattedMessage id="Services.digitalsignage.why.arg1.h" />} icon={{ src: touchIcon, alt: "Services.digitalsignage.why.arg1.alt" }} text={<FormattedMessage id="Services.digitalsignage.why.arg1.p" />} />
                                </LazySlide>
                                <LazySlide duration="1s" delay=".2s" right={true}>
                                    <ServicesDigitalSignageCard title={<FormattedMessage id="Services.digitalsignage.why.arg2.h" />} icon={{ src: targetIcon, alt: "Services.digitalsignage.why.arg2.alt" }} text={<FormattedMessage id="Services.digitalsignage.why.arg2.p" />} />
                                </LazySlide>
                            </div>
                        </div>
                    </section>

                    {/* Einsatzbereiche */}
                    <section className={digitalSignageStyles.applications}>
                        <div>
                            <h3> <FormattedMessage id="Services.digitalsignage.applications.h" /> </h3>

                            <div className={digitalSignageStyles.applicationGrid}>
                                <LazySlide duration="1s">
                                    <ServicesDigitalSignageCard title={<FormattedMessage id="Services.digitalsignage.applications.application1.h" />} icon={{ src: bullhornIcon, alt: "Services.digitalsignage.applications.application1.alt" }} text={<FormattedMessage id="Services.digitalsignage.applications.application1.p" />} />
                                </LazySlide>
                                <ul>
                                    <li>
                                        <FormattedMessage id="Services.digitalsignage.applications.application1.li1" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="Services.digitalsignage.applications.application1.li2" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="Services.digitalsignage.applications.application1.li3" />
                                    </li>
                                </ul>

                                <LazySlide duration="1s">
                                    <ServicesDigitalSignageCard title={<FormattedMessage id="Services.digitalsignage.applications.application2.h" />} icon={{ src: moneyIcon, alt: "Services.digitalsignage.applications.application2.alt" }} text={<FormattedMessage id="Services.digitalsignage.applications.application2.p" />} />
                                </LazySlide>
                                <ul>
                                    <li>
                                        <FormattedMessage id="Services.digitalsignage.applications.application2.li1" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="Services.digitalsignage.applications.application2.li2" />
                                    </li>

                                </ul>

                                <LazySlide duration="1s">
                                    <ServicesDigitalSignageCard title={<FormattedMessage id="Services.digitalsignage.applications.application3.h" />} icon={{ src: chartIcon, alt: "Services.digitalsignage.applications.application3.alt" }} text={<FormattedMessage id="Services.digitalsignage.applications.application3.p" />} />
                                </LazySlide>
                                <ul>
                                    <li>
                                        <FormattedMessage id="Services.digitalsignage.applications.application3.li1" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="Services.digitalsignage.applications.application3.li2" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="Services.digitalsignage.applications.application3.li3" />
                                    </li>
                                </ul>
                            </div>


                        </div>
                    </section>

                    <div className={digitalSignageStyles.process}>

                        {/*  PROZESS */}
                        <HorizontalScrollSection title={<h3> <FormattedMessage id="Services.digitalsignage.process.h" /> </h3>}>

                            <div className={digitalSignageStyles.sliderElement}>
                                <h4>
                                    <span> 01 </span> <FormattedMessage id="Services.digitalsignage.process.element1.h" />
                                </h4>
                                <p>
                                    <FormattedMessage id="Services.digitalsignage.process.element1.p" />
                                </p>
                            </div>
                            <div className={digitalSignageStyles.sliderElement}>
                                <h4>
                                    <span> 02 </span> <FormattedMessage id="Services.digitalsignage.process.element2.h" />
                                </h4>
                                <p>
                                    <FormattedMessage id="Services.digitalsignage.process.element2.p" />
                                </p>
                            </div>
                            <div className={digitalSignageStyles.sliderElement}>
                                <h4>
                                    <span> 03 </span> <FormattedMessage id="Services.digitalsignage.process.element3.h" />
                                </h4>
                                <p>
                                    <FormattedMessage id="Services.digitalsignage.process.element3.p" />
                                </p>
                            </div>
                            <div className={digitalSignageStyles.sliderElement}>
                                <h4>
                                    <span> 04 </span> <FormattedMessage id="Services.digitalsignage.process.element4.h" />
                                </h4>
                                <p>
                                    <FormattedMessage id="Services.digitalsignage.process.element4.p" />
                                </p>
                            </div>
                        </HorizontalScrollSection>
                    </div>


                    {/*  Expample */}
                    <section className={digitalSignageStyles.example}>
                        <div>
                            <div className={digitalSignageStyles.text}>
                                <h3>
                                    <FormattedMessage id="Services.digitalsignage.example.h" />
                                </h3>
                                <p>
                                    <FormattedMessage id="Services.digitalsignage.example.p" />
                                </p>
                                <button className={globalStyles.colorbuttonLight}>
                                    <AniLink cover bg="#F2F2F2" duration={1} direction="up" to={'../projects/?filter=Digital+Signage'}>
                                        <span>  <FormattedMessage id="Services.digitalsignage.example.button" /> </span>
                                    </AniLink>
                                </button>

                            </div>
                            <div className={digitalSignageStyles.mockups}>
                                <LazySlide duration="1s" right={true} >
                                    <div className={digitalSignageStyles.desktopMockup}>
                                        <div className={digitalSignageStyles.buttons}>
                                            <div />
                                            <div />
                                            <div />
                                        </div>
                                        <Image src={DesktopMockup1} alt="Services.digitalsignage.example.alt1" />
                                    </div>
                                    <div className={digitalSignageStyles.mobileMockup}>
                                        <div className={digitalSignageStyles.buttons}>
                                            <div />

                                        </div>
                                        <Image src={MobileMockup} alt="Services.digitalsignage.example.alt1" />
                                    </div>
                                </LazySlide>
                            </div>
                        </div>
                    </section>
                </div>

            </ContentSection>
        </Layout>


    )
}

export default DigitalSignage
